<template lang="pug">
  div.homepage
    div.custom__loader
    main
      section.section.banner__section.mask
        div.container
          div.banner
            div.banner_content
              div.decor
                img(:src="TranslateIcon" alt="icon")
              h1.h1.banner__title Translator
              div.text.banner__text
                p Discover the most powerful and versatile translation application that covers over 130 languages, offering unique features to help you communicate effortlessly in any language.
                p Download our app right now and start translating the world with ease!
              //div.release
              //  div.release__title
              //    | Release date:
              //    |
              //    span 25.09.23
              //  div.date
              //    div.date__item
              //      div.num {{ formatTime(days) }}
              //      span Days
              //    div.date__item
              //      div.num {{ formatTime(hours) }}
              //      span Hours
              //    div.date__item
              //      div.num {{ formatTime(minutes) }}
              //      span Minutes
              //    div.date__item
              //      div.num {{ formatTime(seconds) }}
              //      span Seconds
              a(href="https://apps.apple.com/us/app/translator-voice-camera-scan/id6452722816" target="_blank").appstore
                img(:src="AppstoreIcon" alt="app store")

            div.banner_img
              img(:src="BannerImg" alt="image")

      section.section.functionality__section.mask
        div.container
          functionality-slider

      section#contactUs.section.contact__section.mb-0.mask
        div.container
          contact-form
</template>
<script>
// Images
import TranslateIcon from '@/assets/icons/translate-icon.svg'
import AppstoreIcon from '@/assets/icons/appstore-icon.svg'
import BannerImg from '@/assets/images/banner-img.png'
export default {
  name: 'HomePage',
  components: {
    FunctionalitySlider: () => import('@/components/FunctionalitySlider'),
    ContactForm: () => import('@/components/ContactForm')
  },
  data () {
    return {
      TranslateIcon,
      AppstoreIcon,
      BannerImg,
      targetDate: new Date('2023-09-25 00:00:00'),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  mounted () {
    if (new Date() < this.targetDate) {
      setInterval(this.updateCounter, 1000)
    }
  },
  methods: {
    updateCounter () {
      const currentDate = new Date()
      const timeDifference = this.targetDate - currentDate
      this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
    }
    // formatTime (time) {
    //   return time < 10 ? `0${time}` : time.toString()
    // },
  }
}
</script>
